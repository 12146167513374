<template>
  <v-app>
    <!-- <HeaderComp /> -->
    <router-view></router-view>
    <!-- <FooterComp /> -->
  </v-app>
</template>

<script>
export default {
  name: "RegisterView",
  components: {},
  data() {
    return {};
  },
  methods: {},
  created() {},
};
</script>

<style lang="scss" scoped>
@import "@/scss/app-public.scss";
</style>
