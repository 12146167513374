<template>
  <router-view />
  <v-overlay
    :model-value="$store.state.dashboardLoading"
    contained
    persistent
    class="align-center justify-center"
    :close-on-content-click="false"
  >
    <v-progress-circular
      color="rgb(81, 110, 5)"
      indeterminate
      size="64"
    ></v-progress-circular>
  </v-overlay>
</template>

<script>
// Components

export default {
  name: "App",

  components: {},

  data: () => ({
    //
  }),

  mounted() {},
  watch: {
    // Watch for changes in the 'id' route parameter
    "$store.state.error401": function (newId, oldId) {
      // Do something when the 'id' route parameter changes
      console.log("New ID:", newId);
      console.log("Old ID:", oldId);

      // You can perform actions or fetch data based on the new 'id'
      // For example, load data based on the new 'id'
      console.log(newId, newId !== undefined, newId === undefined);
      if (newId === true) {
        // this.$swal({
        //   icon: "error",
        //   title: "Oops...",
        //   text: "Something went wrong!",
        //   footer: '<a href="#">Why do I have this issue?</a>',
        // });
      }
    },
  },
};
</script>

<style lang="scss">
html {
  overflow: hidden !important;
}

/* .exercise-image {
  position: relative;
  img {
  position: relative;
    width: 100%;
    cursor: pointer;
    border-radius: 8px;
  }
  .exerise-name {
    position: absolute;
    bottom: 10px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 10px;
    width: 100%;
    height: 54px;
  }
} */

.not-available {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-items: center;
  align-items: center;
}

.v-field--active .v-label.v-field-label,
.v-field--focused .v-label.v-field-label {
  opacity: 1;
  background: #87b709 !important;
  color: white;
  padding: 1;
  padding: 1px;
}

.swal2-container {
  // z-index: 2500 !important;
}

.swal2-show {
  z-index: 2500 !important;
}

.v-skeleton-loader__image {
  height: 100% !important;
}

.v-stepper-item {
  padding: 0.5rem !important;
}

.v-skeleton-loader .v-skeleton-loader__image {
  height: 100% !important;
}

.v-badge__badge {
  display: block !important;
  left: 24px !important;
}
</style>
