<template>
  <v-app>
    <!-- <v-layout> -->
    <!-- Main website layout -->
    <v-main>
      <v-app-bar
        style="position: absolute"
        :elevation="0"
        class="home-navbar"
        height="70"
      >
        <div class="home-nav-contain">
          <div class="left">
            <!-- <p style="cursor: pointer;">Workout Scanner</p> -->
            <img
              :src="require('../../assets/gym-logo-1.svg')"
              alt="workout scanner logo"
            />
            <!-- <v-app-bar-title style="font-weight: 700; font-size: 25px; line-height: normal;">Strengthy</v-app-bar-title> -->
          </div>

          <div class="right">
            <v-btn :to="{ name: 'login' }" variant="text"> login </v-btn>
            <v-btn>
              <RegisterDialog />
            </v-btn>
          </div>

          <!-- hamburger menu -->
        </div>
      </v-app-bar>
      <!-- navigation drawer -->
      <div class="websitecontent">
        <router-view></router-view>
      </div>
    </v-main>

    <!-- </v-layout> -->
  </v-app>
</template>

<script>
import { Icon } from "@iconify/vue";
import RegisterDialog from "../../components/websiteComp/home/RegisterDialog.vue";

export default {
  name: "WebsiteLayout",
  components: { Icon, RegisterDialog },
  data() {
    return {
      drawer: null,
      menuList: [
        { name: "Home", link: "/strengthy/home" },
        { name: "About", link: "/strengthy/home" },
        { name: "Class", link: "/strengthy/home" },
        { name: "Trainer", link: "/strengthy/home" },
        { name: "News", link: "/strengthy/home" },
        { name: "Contact", link: "/strengthy/home" },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/website-styles/_websitecolor.scss";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
.v-main {
  font-family: Lato, "sans-serif";
  // background-color: $background;
}

.v-btn {
  text-transform: capitalize;
  box-shadow: none !important;
  border-radius: 0px !important;
  height: 50px;
}

.intro-h5-text {
  text-transform: uppercase;
  font-weight: 400;
  color: $basic-green;
  letter-spacing: 6px;
}

.websitecontent {
  color: $basic-blue;
  min-height: 100vh;
  height: 100vh;
  overflow: visible;
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $basic-green;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    -moz-border-radius: rem(4px);
    -webkit-border-radius: rem(4px);
    border-radius: rem(4px);
  }
}

.v-app-bar {
  background-color: #1b1c1a;
  border-bottom: 1px solid #6a6a6a;
}

.home-navbar {
  // max-width: 1540px;
  // margin: 0px auto;
  right: 0;
}

.home-nav-contain {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
}

.left {
  display: flex;
  align-items: center;
  gap: 10px;
  img {
    width: 150px;
    cursor: pointer;
    padding: 20px;

    @media (max-width: 600px) {
      width: 100px;
      padding: 0px;
    }
  }
}

.right {
  display: flex;
  align-items: center;
  gap: 25px;

  button,
  a {
    font-weight: 600;
    border-radius: none;
    text-transform: capitalize;
    border-radius: 3px !important;
    padding: 0px 25px;

    &:first-child {
      border: 1px solid $basic-green;
      color: $basic-green;
    }
    &:last-child {
      background-color: $basic-green;
    }

    @media (max-width: 600px) {
      font-size: 10px;
      padding: 10px 15px;
    }
    // padding: 15px 20px;
  }

  @media (max-width: 600px) {
    font-size: 10px;
    padding: 10px 15px;
  }
  // padding: 15px 20px;
}
</style>
